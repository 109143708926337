/* Auth Pages Layout */

.auth_bg {
  background-image: url("./assets/images/img-signup.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-color: #f0f0f0 !important;
}

/* Input Component CSS */

.inp-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inp-label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #1c2234;
  line-height: 24px;
}

.inp-error {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #f00;
  line-height: 24px;
}
.inp-mess {
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #6f6f6f;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.inp-wrap {
  z-index: 0;
  height: 37px;
  background-color: #fff;
  border-radius: 8px;
  min-width: 120px;
  cursor: text;
  border: 1px solid #1c2234;
  height: 45px;
  box-shadow: 0px 0px 8px 0px #0000003d;
}

.inp {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  padding: 0;
  font-size: 14px;
  color: #000;
  padding-inline: 25px;
}

.pass-show-eye {
  position: absolute;
  right: 14px;
  font-size: 20px;
  bottom: 11px;
  cursor: pointer;
}

/* Button CSS */

.btn {
  font-weight: 700;
  min-height: 35px;
  text-align: center;
  cursor: pointer;
  padding: 0px 12px;
  font-size: 0.8rem;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-info {
  color: #fff;
  background-color: #08a2b9;
  border: 1px solid #0797ad;
}

.btn-info1 {
  color: #fff;
  background-color: #08a2b9;
  border: 1px solid #0797ad;
}

:hover.btn-info {
  background-color: #138496;
  border: 1px solid #117a8b;
}

/* Canvas */
.cornerstone-canvas{
  height: 100% !important;
  width: 100% !important;
}

.cornerstone-canvas-wrapper {
  height: 100% !important;
  width: 100% !important;
  background: #10141f !important;
  z-index: 1;
}


/* list Dropdown */




/* Responsive ness */

@media screen and (max-height: 800px) {
  .max-h-70px {
    max-height: 70px;
  }

  .max-w-90px {
    max-width: 70px;
    gap: 1px;
  }
  .reduce_scale_9 {
    transform: scale(0.9) !important;
  }
  .reduce_scale_8 {
    transform: scale(0.8) !important;
  }
  .gap-10 {
    gap: 10px;
  }
  .mt-16 {
    margin-top: 20px;
  }
}
