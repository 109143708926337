.darkBG {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 1;
  overflow-y: scroll !important;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  min-width: 70%;
  z-index: 4;
  overflow-y: scroll !important;
}
.centered::-webkit-scrollbar {
  display: none;
}
.smallcentered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 85%;
  min-width: 30%;
  max-width: 50vh;
  max-height: 90vh;
  z-index: 2;
  overflow-y: scroll !important;
}
.smallcentered::-webkit-scrollbar {
  display: none;
}

.modal {
  background: #e5e9f2;
  color: white;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #e5e9f2;
  justify-content: space-between;
  display: flex;
  padding-right: 50px;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
  position: relative;
}

.heading {
  color: #526484;
  font-size: 15px;
  font-weight: 600;
}

.modalmess {
  color: #526484;
  font-size: 15px;
  font-weight: 600;
  padding-inline: 20px;
  text-align: center;
}
.modalmess::-webkit-scrollbar {
  display: none;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  /* color: #2c3e50; */
  /* text-align: center; */
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.actionsContainer1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 4px;
  border-radius: 8px;
  border: none;
  /* font-size: 18px; */
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease-out;
  /* box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06); */
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin: 6px;
  justify-content: center;
  align-items: center;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-2px, 2px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

@media screen and (max-width: 500px) {
  .smallcentered {
    min-width: 80% !important;
    max-width: 80% !important;
  }
  .modalmess {
    padding-inline: 0px;
  }
}

@media screen and (max-width: 300px) {
  .smallcentered {
    min-width: 90% !important;
    max-width: 90% !important;
  }
  .modalmess {
    padding-inline: 0px;
  }
  .modalContent {
    padding: 3px;
  }
}
